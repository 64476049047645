export const postBearers = [
  {
    id: 1,
    name: 'Sumit Kumar Mathpati',
    post: 'President',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712001408/Rotaract%20Post%20Bearers%202023-24/Sumit_h4f0jz.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/sumit-kumar-mathpati-03692b22b',
  },
  {
    id: 2,
    name: 'Priyesh Kumar Dutta',
    post: 'Secretary',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000290/Rotaract%20Post%20Bearers%202023-24/Priyesh_esaxsq.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/priyesh-kumar-dutta-367016296',
  },
  {
    id: 3,
    name: 'Md Shahzaman Belali',
    post: 'Vice President',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000320/Rotaract%20Post%20Bearers%202023-24/Shahzaman_qejapj.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/shahzaman50',
  },
  {
    id: 4,
    name: 'Manisha Kumari',
    post: 'Vice President',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000296/Rotaract%20Post%20Bearers%202023-24/Manisha_hgmwc2.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/manisha-kumari-277b58240',
  },
  {
    id: 5,
    name: 'Pratap Kumar Dey',
    post: 'Treasurer',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000488/Rotaract%20Post%20Bearers%202023-24/Pratap_gycbuo.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/pratap-kumar-dey-47662522a',
  },
  {
    id: 6,
    name: 'Vishnu Jha',
    post: 'Joint Secretary',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712002577/Rotaract%20Post%20Bearers%202023-24/Vishnu_ijhdiq.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/vishnu-jha-247563229',
  },
  {
    id: 7,
    name: 'Rajshree',
    post: 'Joint Secretary',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000561/Rotaract%20Post%20Bearers%202023-24/Rajshree_brjp7d.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/raj-shree-508709230',
  },
  {
    id: 8,
    name: 'Abhishek Raj',
    post: 'Joint Treasurer',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000235/Rotaract%20Post%20Bearers%202023-24/Abhishek_y9pqn4.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/abhishek-raj-462666236',
  },
  {
    id: 9,
    name: 'Ayushi Kumari',
    post: 'Joint Treasurer',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712002640/Rotaract%20Post%20Bearers%202023-24/Ayushi_za1nyc.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/ayushi-kumari-35375923a',
  },
  {
    id: 10,
    name: 'Ujjwal Singh',
    post: 'International Services Director',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000461/Rotaract%20Post%20Bearers%202023-24/Ujjwal_tqsyjj.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/ujjwal-kumar-68378523b',
  },
  {
    id: 11,
    name: 'D Raunak',
    post: 'Editor in Chief',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712002607/Rotaract%20Post%20Bearers%202023-24/Rounak_vw4jkw.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/d-raunak-98bb401b2',
  },
  {
    id: 12,
    name: 'Hritik Kumar',
    post: 'Alumni Incharge',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000259/Rotaract%20Post%20Bearers%202023-24/Hritik_jhdf8e.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/hritik-kumar-82016922b',
  },
  {
    id: 13,
    name: 'Shubhanshu Kumar',
    post: 'Design & Technical Head',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712001403/Rotaract%20Post%20Bearers%202023-24/Shubhanshu_srouha.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/shubhanshu-kumar-a5616322a',
  },
  {
    id: 14,
    name: 'Shashikant Mahato',
    post: 'Club services',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712001393/Rotaract%20Post%20Bearers%202023-24/Shashikant_blyopr.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/shashikant-',
  },
  {
    id: 15,
    name: 'Shilpi Kumari',
    post: 'Public Relations Officer',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712002581/Rotaract%20Post%20Bearers%202023-24/Shilpi_uxoxz9.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/shilpi-kumari-6a6556234',
  },
  {
    id: 16,
    name: 'Aditya Kumar',
    post: 'Sponsorship & Event Incharge',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000247/Rotaract%20Post%20Bearers%202023-24/Aditya_gxtl6s.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/itsadi',
  },
  {
    id: 17,
    name: 'Rimil Hansda',
    post: 'Sergeant-at-Arms',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712002619/Rotaract%20Post%20Bearers%202023-24/Rimil_ikiprv.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/rimil-hansda-702b24256',
  },
  {
    id: 18,
    name: 'Mridula Khushi',
    post: 'Liaison Officer',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712000307/Rotaract%20Post%20Bearers%202023-24/Mridula_pphvkg.jpg',
    LinkdeIn: 'https://www.linkedin.com/in/mridula-khushi-7966b0254',
  },
];

export const rotoEvents = [
  {
    id: 1,
    date: '14th February',
    name: 'Saraswati Puja',
    desc: 'The Rotaract club of BIT SINDRI joyfully celebrated Saraswati Puja on 14th February, 2024. The rituals were performed properly and the clubroom was decorated beautifully. All the students, teaching and non teaching staffs attended to make the day memorable. The Hawan took place at the evening of Vasant Panchami. On the next day, the idol was bid farewell successfully.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081456/Rotaract%20Events/DocScanner_2_Apr_2024_11-36_am_mu9iu1.jpg',
  },
  {
    id: 2,
    date: '9th-11th February, 2024',
    name: '56th Eye Relief Camp (ERC)',
    desc: 'The Rotary Sindri and the Rotaract Club of BIT Sindri, supported by SAIL, organized 56th Eye Relief Camp at the CHC Gaushala from 9th February to 11th February, 2024. The ED of SAIL and Rotarian Ranjit Singh inaugurated this three day camp as chief guests, joined by esteemed officers of SAIL and members of Rotary Sindri. Around 150+ patients were registered for the screening. The medical team conducted 79 successful cataract operations. The day symbolized the power of collective action and compassion.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081454/Rotaract%20Events/DocScanner_2_Apr_2024_11-39_am_jehfek.jpg',
  },
  {
    id: 3,
    date: '26th January, 2024',
    name: '75th Republic Day',
    desc: 'The 75th Republic Day celebration was observed with grandeur and patriotic fervor on January 26, 2024. The event was organised and carried out outside the Metal-Chemical Engineering Department. The day commenced with the hoisting of the national flag by our Director Prof (Dr) Pankaj Rai Sir, Prof. Dr. Ghanshyam Sir and other dignitaries. The tricolor fluttered proudly against the backdrop of the azure sky, evoking a sense of pride and patriotism.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081451/Rotaract%20Events/DocScanner_2_Apr_2024_11-43_am_xp2xit.jpg',
  },
  {
    id: 4,
    date: '16th January, 2024',
    name: 'Interation with Specially-Abled Children',
    desc: `The Rotaract Club of BIT Sindri visited Jeevan Jyoti School on 16th January 2024 with a powerful message: "Abled does not mean enabled. Disabled does not mean less abled." This visit was more than just a casual interaction; it was a testament to the club's commitment to inclusivity and understanding. Through engaging games and activities like musical chairs, throw ball, barriers were broken, and the unique talents of each child were celebrated. This event showcased the belief that every child, regardless of their challenges, deserves recognition and equal opportunities.`,
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081461/Rotaract%20Events/DocScanner_2_Apr_2024_11-33_am_ooab6n.jpg',
  },
  {
    id: 5,
    date: '17th December, 2023',
    name: 'Vivartan',
    desc: "The Post Installation Ceremony, Vivartan, took place on December 17th, 2023. The day marked the beginning of a new chapter in the journey of leadership and dedication. With Chief Guest Secretary Ranjeet Sir from Rotary Sindri, it wasn't just about passing the torch; it was welcoming new post holders. It was a momentous occasion that heralded a promising start to a new era of leadership excellence.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081459/Rotaract%20Events/DocScanner_2_Apr_2024_11-42_am_mm4c82.jpg',
  },
  {
    id: 6,
    date: '3rd December, 2023',
    name: 'World Disability Day Rally',
    desc: 'The Rotaract  Club of BIT Sindri, with Rotary Club of Dhanbad, organized a "DISABILITY AWARENESS RALLY & CULTURAL PROGRAM" on World Disability Day. Chief Guest Shri Ravi Raj Sharma and Guest of Honor Shri Anup Kumar joined us to promote inclusivity and equal opportunities for specially-abled children from Jeevan Jyoti School.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081417/Rotaract%20Events/DocScanner_2_Apr_2024_11-40_am_pg75gf.jpg',
  },
  {
    id: 7,
    date: '12th November, 2023',
    name: 'Karuna',
    desc: 'On the special occasion of Diwali, The Rotaract Club Of BIT Sindri came up with the special idea "Karuna". On 12th November 2023, our team embraced the true spirit of Diwali by purchasing from small vendors, distributing sweets to children, making donations to orphans and many more. We seek to create more positive impacts in our society in upcoming future.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081526/Rotaract%20Events/DocScanner_2_Apr_2024_11-37_am_wuzpue.jpg',
  },
  {
    id: 8,
    date: '11th September, 2023',
    name: 'Pankh Phase II',
    desc: 'The Rotaract club of BIT Sindri concluded its annual sanitary pad distribution and awareness drive for underprivileged girls at Rajkiya Buniyadi Vidyalaya, BIT Sindri Campus. The members themselves spoke on the topic and provided proper knowledge and guidance about periods and sanitary pads to the students. A total of 400 pads were distributed free of charge among the girls. Receiving a fruitful response and interaction with the audience made us feel like all our hard work had paid off.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081408/Rotaract%20Events/IMG-20240402-WA0010_ilaxkc.jpg',
  },
  {
    id: 9,
    date: '1st to 3rd September, 2023',
    name: 'Mega Limb Camp, Dhanbad',
    desc: 'Members of the Rotaract Club of BIT Sindri volunteered in the Mega Limb Camp, which was organised by the Gerry Powell Jaipur Centre at Jain Milan, Jodaphatak, Dhanbad. This event is a signature initiative of the Rotary Club of Dhanbad. More than 130 artificial limbs were provided to the patients.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081429/Rotaract%20Events/IMG-20240402-WA0012_c1ymqd.jpg',
  },
  {
    id: 10,
    date: '10th September, 2023',
    name: 'Mega Health Chekup Camp, Tundi',
    desc: 'Members of the Rotaract Club of BIT Sindri volunteered at the Mega Health Camp at Swarup Saraswati Vidya Mandir in Ratanpur, Tundi. The camp was organized by the Rotary Club of Dhanbad. The event aimed to provide free healthcare services to the school and local community, emphasizing preventive care and health awareness. Beneficiaries were offered a wide range of essential medical services, including general health checks, dental, eye, skin, blood, and many more checkups. Free medicines were also provided to attendees, ensuring they had access to necessary medications. More than 600 individuals benefited from the services provided.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522523/Rotaract%20Events/tundi_camp_iydixq.jpg',
  },
  {
    id: 11,
    date: '21st August, 2023',
    name: 'Pankh Phase I',
    desc: 'The Rotaract club of BIT Sindri organised its annual Sanitary Pad distribution and awareness drive for underprivileged girls. Members visited three different schools, starting from DAV Public School, Rangamati, to Utkramit High School, Rangamati, and DAV High School, Tasra. The members themselves spoke on the topic and provided proper knowledge and guidance about periods and sanitary pads to the students. A total of 3,000 pads were distributed free of charge among the girls. Receiving a fruitful response and interaction with the audience made us feel like all our hard work paid off.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522236/Rotaract%20Events/Pankh_I_mbpmae.jpg',
  },
  {
    id: 12,
    date: '14th August, 2023',
    name: 'Blood Donation Camp: Sindri',
    desc: 'The members of the Rotaract club of BIT Sindri volunteered at the blood donation camp organised by Jan Adhikar Manch, Sindri, on August 14. The camp was organised at Dr. Shyama Prasad Mukherjee Inter College, Goshala, Sindri. The chief guest, MLA Jharia Purnima Niraj Singh, inaugurated the camp. Registrations were done, and the donors were served refreshments and given proper care. The exceptional thing about this camp was that it had a target of 77 donations to complete. The target was kept on account of the completion of 77 years of independence and was completed before 4 pm.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522220/Rotaract%20Events/blood_donation_spm_ekmoen.jpg',
  },
  {
    id: 13,
    date: '15th August, 2023',
    name: '77th Independence Day Celebration',
    desc: 'Rotaract Club of BIT Sindri paid tribute to the freedom fighters of India by hoisting the national flag and remembering their valour and hardships to fulfill the dream of an independent nation. With the theme of Nation First, Always First, we celebrated the 77th Independence Day.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522234/Rotaract%20Events/Independence_day_2023_e5uff6.jpg',
  },
  {
    id: 14,
    date: '15th-17th November,2022',
    name: '55th Eye Relief Camp (ERC)',
    desc: 'Rotaract Club of BIT Sindri successfully concluded its signature event, the EyeRelief Camp, on November 17, 2022.The three-day programme, which was sponsored by SAIL, went off without a hitch at the Common Health Centre at Gaushala, Sindri. The registration of the patients began on 15th November, the first day of the event, which was inaugurated by SAIL executives Aditya Kumar Singh and Vidya Bhushan Pandey. On the second day of the camp, cataract operations were performed on over 58 patients. The beneficiaries were not only from Sindri, but also from Dhanbad as well.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1712081448/Rotaract%20Events/IMG-20240402-WA0011_wgvovi.jpg',
  },
  {
    id: 15,
    date: '18th December,2022',
    name: 'Eye Checkup Camp',
    desc: 'After the successful completion of 55th Eye Relief Camp, the Rotaract Club of BIT SINDRI organized a free Eye Checkup Camp on 18thDecember 2022 for patients treated with Cataract at the Common Health Centre, Gaushala. The members of the Rotaract Club were present for guiding and helping the patients in the camp. Mr. Ranjeet Singh, Secretary of Rotary Club, Sindri graced the occasion. The members were all so pleased to receive blessings from the patients.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522215/Rotaract%20Events/Eye_checkup_camp_oo0cme.jpg',
  },
  {
    id: 16,
    date: '26th February,2023',
    name: 'LN-4 Limb Camp',
    desc: 'The members of the Rotaract club of BIT Sindri volunteered at a welfare mechanical limb camp organised by Rotary Dhanbad on February 26 at Jeevan Jyoti School in Dhanbad. The limb fitting was done in different steps: photo in, fitting, training, counselling, and photo out. Our task was to guide them and see that all the procedures were done correctly. The beneficiaries were happy to be able to use their hands and do all the things they wanted to. We were happy to see their smiles and excitement, and the blessings we received made us feel so proud.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522230/Rotaract%20Events/Mega_limb_camp_tu4iia.jpg',
  },
  {
    id: 17,
    date: '25th September,2022',
    name: 'Sanitary Pad Distribution',
    desc: "On the auspicious occasion of Kalash Sthapna, the members of Rotaract Club of BIT Sindri organised an event for 'Raising awareness about Menstrual Hygiene and Free Sanitary Pads Distribution'. In this event, more than 1000 pads were distributed among the girls of class 7th to12th of DAV High School, Pathardih and Mother Teresa High School, Baliapur.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522239/Rotaract%20Events/Sanitary_pad_iwohod.jpg',
  },
  {
    id: 18,
    date: '26th January,2023',
    name: 'Saraswati Puja',
    desc: 'The Rotaract club of BIT Sindri celebrated Vasant Panchami with the students of the college. We performed Saraswati Puja in our club room on January 26 and 27, 2023, that was beautifully hand decorated by the club members. On the 26th, we performed all rituals according to the instructions of the priest. After the puja, offerings were distributed among the devotees present there. The next day, on the 27th, Hawan was organised, and in the evening, we bid farewell to the idol with a beautiful and grand celebration with colours.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522246/Rotaract%20Events/saraswati_puja_olkbps.jpg',
  },
  {
    id: 19,
    date: '1st May,2023',
    name: 'Rudrabhishek Puja',
    desc: "Carrying on with our traditions, the Rotaract club of BIT Sindri performed Rudrabhishek Puja in Baba Thaneshwar Bhootnath Mandir, BIT Sindri, on May 1, 2023.Under the guidance of the priest, all rituals were practiced by the members. The ceremony was followed by the chanting of mantras, hawan, and Pushpanjali. After the puja, offerings were distributed among the devotees. Rudrabhishek Puja is performed to alleviate one's sufferings and bring prosperity to one's family.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522243/Rotaract%20Events/Rudrabhishekh_2023_ndhzle.jpg',
  },
  {
    id: 20,
    date: '3rd December,2022',
    name: 'Awareness Rally on World Disability Day',
    desc: 'December 3 is celebrated as World Disability Day and is about promoting the rights and well-being of persons with disabilities at every level of society. On this day, the members of Rotaract Club of BIT Sindri volunteered in Walk to Freedom Rally organised by Rotary Club of Dhanbad with the special children of Jeevan Jyoti School and Blind School of Dhanbad which started from Jeevan Jyoti School. The rally began after lighting of candle and speech by District Commisioner, Shri Sandeep Singh and Circle Officer of Dhanbad.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522221/Rotaract%20Events/Disability_day_pq7nn0.jpg',
  },
  {
    id: 21,
    date: '27th November,2022',
    name: 'Vivartan',
    desc: "It is the same show, characters and responsibilities have the same glow, just the faces are getting switched. 'VIVARTAN: Change Over Day' - The Post Installation Ceremony has announced its arrival. The day has arrived when the post holders will retire and start a new journey in life. It is now the time to welcome the next generation of leaders. New faces, new energy but the same motto of serving.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522247/Rotaract%20Events/vivartan_2022_dnryfq.jpg',
  },
  {
    id: 22,
    date: '26th January,2023',
    name: 'Republic Day',
    desc: 'The Rotaract Club of BIT Sindri paid tribute to the makers of our nation by hoisting the national flag and remembering their contribution to our Republic India. All the members celebrated the 74th Republic Day under the theme of Jan Bhagidari.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522248/Rotaract%20Events/indpendence_day_2022_priybg.jpg',
  },
  {
    id: 23,
    date: '12th June, 2023',
    name: 'World Against Child Labour Awareness Campaign',
    desc: "Small hand can handle a pen better. Lend your support to end child labour.Child labour is the exploitation of children who are deprived of their childhood by work. This prevents them from attending school and causes physical, mental and social harm.Taking an initiative to stop this trauma, Rotaract Club of BIT Sindri is spreading awareness by pinning posters at different cities of Jharkhand. The posters were designed to make people aware about a child's education and progress by not making them work.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522231/Rotaract%20Events/child_labour_day_pcyu7d.jpg',
  },
  {
    id: 24,
    date: '17th June, 2022',
    name: 'Blood Donation Camp',
    desc: "On 17th of June 2022, volunteers of Rotaract Club of BIT Sindri volunteered in Blood Donation Camp which was held at Jivan Jyoti School, Dhanbad for the people who were in need of it. Volunteers of Rotaract Club of Sindri served the people who have donated the blood, they have helped them to have a refreshment like water, fruits so that they can't feel more weakness. This event was successfully completed.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522220/Rotaract%20Events/blood_donation_dhanbad_2022_eyswld.jpg',
  },
  {
    id: 25,
    date: '1st July, 2022',
    name: 'Plantation Drive',
    desc: 'The members of Rotaract club of BIT Sindri welcome the new beginning, the new era, the transformation and the new Rota Year together as one by Planting a sapling in the irrespective localities on Friday 1st July 2022. The last year, we went Beyond the limit and now, we believe that together everyone achieves more.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522234/Rotaract%20Events/Plantation_drive_2022_or2uud.jpg',
  },
  {
    id: 26,
    date: '10th April, 2022',
    name: 'Spreading Smiles',
    desc: 'On the auspicious occasion of Ram Navami, Rotaract club of BIT Sindri successfully completed its first offline event after the pandemic, Spreading Smiles, where clothes and stationary were distributed among the needy. The distribution of clothes and stationary took place in Motinagar, Sindri. Morethan 150 clothes were distributed among 75+ beneficiaries.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694524746/Rotaract%20Events/spreading_smiles_o6k8he.jpg',
  },
  {
    id: 27,
    date: '15th August, 2022',
    name: 'Flag Hoisting on Independence Day',
    desc: "Rotaract Club of BIT Sindri paid tribute to the freedom fighters of India by hoisting the national flag and remebering their valourand hardships to fulfill the dream of an independent nation with the theme of 'Har Ghar Tirange' to commemorate 75 years of independence.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522226/Rotaract%20Events/26th_jan_2023_lly01b.jpg',
  },
  {
    id: 28,
    date: '20th July, 2022',
    name: 'International Chess Day',
    desc: 'Like every year, the members of Rotaract club of BIT Sindri celebrated International Chess Day on 20th July 2022 by dressing themselves in black and white attire to represent it as a tussle of life.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522213/Rotaract%20Events/International_chess_day_tkscov.jpg',
  },
  {
    id: 29,
    date: '24th October,2022',
    name: 'Mudita',
    desc: "To bring smiles on local vendor's faces, Rotaract club of BIT Sindri came up with an initiative called 'MUDITA'. Donating clothes, sweets, and candles and helping local artisans and vendors by purchasing earthen oil lamps among others so that they too can light their houses during Deepavali.",
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522249/Rotaract%20Events/Mudita_ko48ng.jpg',
  },
  {
    id: 30,
    date: '28th April, 2022',
    name: 'Rudrabhishek Puja',
    desc: 'Carrying on the traditions, Rotaract club of BIT Sindri performed Rudrabhishek Puja at Baba Thaneshwar Bhootnath Mandir, BIT Sindri, on 28th April, 2022. All the rituals were performed in accordance with the instructions of the priestof the temple. The ceremony ended with the chanting of Rudrashtakam, Yagya, followed by Aarti. On completion of the puja ceremony, offerings were distributed among all the devotees present there. May Lord Shiva shower his blessings on all of us and help us continue the work we do for the society.',
    img: 'https://res.cloudinary.com/djqfem5lk/image/upload/v1694522239/Rotaract%20Events/Rudrabhishekh_2022_tplejk.jpg',
  },
];
