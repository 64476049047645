import { ArrowLeftOutlined, ArrowRightOutlined } from '@material-ui/icons';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { rotoEvents } from '../data';
import { mobile } from '../responsive';
import background from '../dragon-scales.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
`;
const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === 'left' && '10px'};
  right: ${(props) => props.direction === 'right' && '10px'};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;
const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${mobile({ height: 'auto', marginBottom: '20px' })}
`;
const ImageContainer = styled.div`
  height: 100%;
  flex: 1;
`;
const Image = styled.img`
  height: 80%;
  min-width: 367;
  object-fit: cover;
  position: relative;
  top: 30px;
  padding-left: 10px;
  ${mobile({ maxHeight: '250px', paddingLeft: '0px', top: '0px' })}
`;
const InfoContainer = styled.div`
  flex: 1;
  padding: 50px;
`;
const Title = styled.h1`
  font-size: 40px;
  margin-left: -20px;
  font-family: Montserrat;
  color: white;
  font-weight: bold;
  ${mobile({
    fontSize: '25px',
    position: 'relative',
    marginTop: '-30px',
    marginLeft: '-30px',
  })}
`;
const Desc = styled.p`
  margin: 30px -20px;
  font-size: 15px;
  font-weight: 500;
  font-family: Montserrat;
  letter-spacing: 1.5px;
  color: white;
  ${mobile({
    fontSize: '10px',
    position: 'relative',
    maxHeight: '300px',
    overflowY: 'scroll',
    marginLeft: '-30px',
    marginRight: '-30px',
    marginBottom: '-10px',
  })}
`;
const Cards = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 20px black;
  height: 550px;
  background-color: rgba(28, 104, 139, 0.3);
  ${mobile({ flexDirection: 'column', height: 'auto' })}
`;
const EventWrapper = styled.div`
  // background-size: 100px 50px;
  background-image: url(${background});
`;
const Head = styled.h1`
  color: white;
  font-size: 4rem;
  font-family: Courgette;
  align-items: center;
  align-self: center;
  text-align: center;
  padding-top: 40px;
  ${mobile({ fontSize: '2.5rem' })}
`;

const EventSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction) => {
    if (direction === 'left') {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 29);
    } else {
      setSlideIndex(slideIndex < 29 ? slideIndex + 1 : 0);
    }
  };
  return (
    <EventWrapper>
      <Head>OUR EVENTS</Head>
      <Container id="slides">
        <Arrow direction="left" onClick={() => handleClick('left')}>
          <ArrowLeftOutlined />
        </Arrow>
        <Wrapper slideIndex={slideIndex}>
          {rotoEvents.map((item) => (
            <Slide key={item.id}>
              <Cards>
                <ImageContainer>
                  <Image src={item.img}></Image>
                </ImageContainer>
                <InfoContainer>
                  <Title>{item.name}</Title>
                  <Desc>
                    {item.date}
                    <br />
                    {item.desc}
                  </Desc>
                </InfoContainer>
              </Cards>
            </Slide>
          ))}
        </Wrapper>
        <Arrow direction="right" onClick={() => handleClick('right')}>
          <ArrowRightOutlined />
        </Arrow>
      </Container>
    </EventWrapper>
  );
};

export default EventSlider;
